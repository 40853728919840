import React from "react"
import IframeResizer from "iframe-resizer-react"
import PropTypes from "prop-types"

import Wrapper from "../layout/wrapper/wrapper"

import styles from "./form.module.scss"

const Form = ({ link, title, background, noPadding, sectionTitle, slug, noId = false }) => (
  <Wrapper background={background} noPadding={noPadding}>
    {sectionTitle ? (
      <h2 className={styles.sectionTitle} id={slug?.current}>
        {sectionTitle}
      </h2>
    ) : null}
    <IframeResizer src={link} width="100%" id={noId ? "" : "pardot-form"} title={title} />
  </Wrapper>
)

export default Form

Form.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  background: PropTypes.string,
  noPadding: PropTypes.bool,
}
